(function ($) {

$(document).on('ready', function () {

  /**
   * Загрузка страницы
   */
  var viewportWidth = $(window).width();
  var breakPoint = 1440;

  $('html').removeClass('no-js');

  /**
   * Плавная прокрутка
   */
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .on('click', function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!

        });
      }
    }
  });

  /**
   * Мобильное навигационное меню
   */
  var $nav = $('.nav');

  $nav.removeClass('nav--nojs');
  $nav.find('.nav__toggle').on('click', function () {
    if ($nav.hasClass('nav--close')) {
      $nav.removeClass('nav--close').addClass('nav--open');
    } else {
      $nav.removeClass('nav--open').addClass('nav--close');
    }
  });

  /**
   * Секция "Видео"
   */
  var $video = $('.video');
  var media = $video.find('.video__media').get(0);
  var pauseTime = media.currentTime;

  $video.on('click', function () {
    var $play = $video.find('.video__play');
    var $videoContent = $video.find('.video__container');

    if (media.paused) {
      media.currentTime = pauseTime;
      media.play();
      $play.addClass('hidde');
      $video.addClass('video--play');
    } else {
      pauseTime = media.currentTime;
      media.load();
      $play.removeClass('hidde');
      $video.removeClass('video--play');
    }
  });

  /**
   * Секция "Как это работает"
   */
  howListInit();
  $(window).on('resize', function() {
    viewportWidth = $(window).width();

    if (viewportWidth < breakPoint) {
      howListInit();
    }
  });

  function howListInit() {
    $('.how__list').not('.slick-initialized').slick({
      responsive: [{
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: breakPoint,
          settings: {
            arrows: false,
            dots: true,
            fade: true,
          }
        }
      ]
    });
  }

  /**
   * Секция "Правила"
   */
  infoListInit();
  $(window).on('resize', function() {
    var viewportWidth = $(window).width();

    if (viewportWidth < breakPoint) {
      infoListInit();
    }
  });

  function infoListInit() {
    $('.info__list').not('.slick-initialized').slick({
      responsive: [{
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: breakPoint,
          settings: {
            dots: false,
            nextArrow: $('.info__next')
          }
        }
      ]
    });
  }

  /**
   * Секция "Часто задаваемые вопросы"
   */
  $('.faq__list').accordion({
    collapsible: true,
    heightStyle: 'content',
    icons: {
      "header": "ui-icon-open",
      "activeHeader": "ui-icon-close"
    }
  });



});

})(jQuery);
